<template>
  <div class="pa-4">
    <div>
      <h3 class="text-decoration-underline">Order Detail</h3>
      <v-data-table
        :hide-default-footer="true"
        :items="order.cart.cart_item"
        style="border: solid 1px red"
      >
        <template v-slot:header="{}">
          <thead class="white--text" style="background-color: #555555">
            <tr>
              <td class="text-center">SKU</td>
              <td class="text-center">Name</td>
              <td class="text-center">Quantity</td>
              <td class="text-center">Unit Price</td>
              <td class="text-center">Line Total</td>
            </tr>
          </thead>
        </template>
        <template v-slot:item="{ item }">
          <tr>
            <td class="text-center">{{ item.sku }}</td>
            <td class="text-center">{{ item.part.name }}</td>
            <td class="text-center">{{ item.quantity }}</td>
            <td class="text-center">{{ item.price_per_piece }}</td>
            <td class="text-center">{{ item.total_price }}</td>
          </tr>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'OrderDetail',
  data() {
    return {
      OrderDetail: []
    }
  },
  computed: {
    ...mapState({
      order: (state) => state.Order.order
    })
  },
  props: {
    orderId: {
      default: null
    }
  }
}
</script>

<style>
</style>