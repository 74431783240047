<template>
  <div class="fill-height pa-5 py-3 py-md-10">
    <h2 class="pt-2 pb-2">{{ $t('header.myPurchase') }}</h2>
    <v-skeleton-loader
      v-if="isLoading"
      class="mx-auto"
      max-width="auto"
      type="table"
    ></v-skeleton-loader>
    <div v-if="!isLoading">
      <v-row justify="space-between" align="center">
        <v-col lg="8" cols="12" class="flex-center-col row">
          <v-col lg="4" cols="6">
            <v-select
              class="mr-2"
              :clearable="true"
              dense
              v-model="shipping_status"
              :items="statusList"
              label="STATUS"
              @change="search"
              outlined
            ></v-select>
          </v-col>
          <v-col lg="4" cols="6">
            <v-select
              dense
              :clearable="true"
              v-model="payment_status"
              :items="payby"
              label="PAY BY"
              @change="search"
              outlined
            ></v-select>
          </v-col>
          <v-col lg="4" cols="12">
            <v-text-field
              dense
              v-model="keyword"
              label="SEARCH"
              @change="search"
              outlined
            ></v-text-field>
          </v-col>
        </v-col>
        <v-col lg="2" cols="12">
          <v-select
            dense
            v-model="row"
            :items="row_selector"
            label="ROWS"
            @change="search"
            outlined
          ></v-select>
        </v-col>
      </v-row>
      <v-row align="center">
        <v-col md="1" cols="12">{{ $t('orderPage.status') }} : </v-col>
        <v-col
          v-for="(item, index) in statusList"
          :key="index"
          md="2"
          sm="3"
          cols="4"
          class="row align-center"
        >
          <v-col md="3" cols="12">
            <v-img :src="require(`../assets/img/status/${item}.png`)"> </v-img
          ></v-col>
          <v-col md="9" cols="12">
            {{ $t(`orderStatus.${item}`) }}
          </v-col>
        </v-col>
      </v-row>
      <v-data-table :hide-default-footer="true" :items="paginateOrders">
        <template v-slot:header="{}">
          <thead class="white--text" style="background-color: #555555">
            <tr>
              <td class="text-center">{{ $t('orderPage.order') }}</td>
              <td class="text-center">{{ $t('orderPage.status') }}</td>
              <td class="text-center">{{ $t('orderPage.pay') }}</td>
              <td class="text-center">{{ $t('orderPage.totalPayment') }}</td>
              <td class="text-center">{{ $t('orderPage.dateOfPayment') }}</td>
              <td class="text-center">{{ $t('orderPage.trackingNumber') }}</td>
              <td class="text-center">{{ $t('orderPage.transport') }}</td>
              <td class="text-center">{{ $t('orderPage.detail') }}</td>
            </tr>
          </thead>
        </template>
        <template v-slot:item="{ item }">
          <tr>
            <td class="text-center">{{ item.reference_number }}</td>
            <td class="text-center">
              <v-img
                class="mx-auto"
                width="40px"
                contain
                :src="
                  require(`../assets/img/status/${item.shipping_status}.png`)
                "
              >
              </v-img>
            </td>
            <td align="center">
              <v-img
                class="mx-auto"
                width="100px"
                contain
                :src="require(`../assets/img/${payLogo(item.pay_by)}.png`)"
              >
              </v-img>
            </td>
            <td class="text-right">
              <v-chip color="green" text-color="white">
                {{ item.currency }}
                {{
                  formatNumber(
                    parseFloat(item.transfer_amount ? item.transfer_amount : 0)
                  )
                }}
              </v-chip>
            </td>
            <td class="text-center">
              {{ item.created_at | moment('DD MMMM YYYY HH:mm:ss') }}
            </td>
            <td class="text-center">{{ item.tracking_number }}</td>
            <td class="text-center">{{ item.transport_company }}</td>
            <td class="text-center justify-center">
              <v-row class="justify-center">
                <v-col md="4" s cols="12">
                  <v-btn
                    @click="getDetail(item)"
                    class="cust-border-btn-color"
                    >{{ $t('orderPage.detail') }}</v-btn
                  >
                </v-col>
                <v-col md="4" cols="12">
                  <OrderExport
                    v-if="item"
                    :addressBilling="item.address"
                    :inCart="item.cart.cart_item"
                    :sell_rate="item.sell_rate"
                    :vat="item.vat"
                    :deliveryCost="item.shipping_price"
                    :cartDetail="item.cart"
                    :currency="item.currency"
                  ></OrderExport>
                </v-col>
              </v-row>
            </td>
          </tr>
        </template>
      </v-data-table>
      <v-pagination
        v-model="pagination.page"
        :length="pagination.pageCount"
        circle
        @input="changePage"
      ></v-pagination>
    </div>

    <v-dialog v-model="dialog" max-width="700">
      <div v-if="dialog" style="background-color: white">
        <OrderDetail :model="row"></OrderDetail>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import OrderExport from '../components/OrderExport'
import { formatNumber } from '@/plugins/global-funcs'
import { mapState } from 'vuex'
import OrderDetail from './OrderDetail'
export default {
  name: 'CustomerOrder',
  metaInfo: {
    title: `Thai bike parts`,
    titleTemplate: 'My Purchese | %s'
  },
  data() {
    return {
      formatNumber: formatNumber,
      keyword: '',
      selectedRow: null,
      shipping_status: '',
      payment_status: '',
      isLoading: false,
      row_selector: [5, 10, 15],
      row: 5,
      order: null,
      shipping: null,
      dialog: false,
      payby: ['PAYPAL', 'CREDIT_CARD', 'TRANSFER'],
      pagination: {
        page: 1,
        itemsPerPage: 15,
        pageStart: 1,
        pageStop: 1,
        pageCount: 0
      },
      paginateOrders: [],
      statusList: [
        'Checking',
        'Preparing',
        'Shipping',
        'Delivered',
        'Cancelled'
      ]
    }
  },
  methods: {
    payLogo(payType) {
      switch (payType) {
        case 'PAYPAL':
          return 'paypal'
        case 'CREDIT_CARD':
          return 'omise'
        case 'TRANSFER':
          return 'transfer'
        default:
          return null
      }
    },
    getDetail(item) {
      this.$store.dispatch('Order/onGetOrderDetail', { order_id: item.id }).then((response)=>{
        this.selectedOrder = response
      })
      this.dialog = true
    },
    async search() {
      this.isLoading = true
      ;(this.pagination.page = 1),
        await this.$store.dispatch('Order/onGetPaginateOrder', {
          page: this.pagination.page,
          row: this.row,
          checkout: this.shipping_status,
          pay_by: this.payment_status,
          token: this.accessToken,
          keyword: this.keyword
        }).then((response) => {
        this.paginateOrders = response.data
        this.pagination = response.pagination
      })
      this.isLoading = false
    },
    async changePage(val) {
      this.isLoading = true
      await this.$store.dispatch('Order/onGetPaginateOrder', {
        page: val,
        row: this.row,
        checkout: this.shipping_status,
        pay_by: this.payment_status,
        token: this.accessToken,
        keyword: this.keyword
      }).then((response) => {
        this.paginateOrders = response.data
        this.pagination = response.pagination
      })
      this.isLoading = false
    }
  },
  components: {
    OrderDetail,
    OrderExport
  },
  computed: {
    ...mapState({
      accessToken: (state) => state.Auth.userDetail.access_token,
      //selectedOrder: (state) => state.Order.order,
      // paginateOrders(state) {
      //   return state.Order.paginateOrders
      // },
      // pagination(state) {
      //   return state.Order.pagination
      // },
      paymentStatus: (state) => state.Order.paymentStatus,
      shippingStatus: (state) => state.Order.shippingStatus
    })
  },
  created() {
    this.$store
      .dispatch('Order/onGetPaginateOrder', {
        page: 1,
        row: this.row,
        checkout: this.shipping_status,
        pay_by: this.payment_status,
        token: this.accessToken
      })
      .then((response) => {
        this.paginateOrders = response.data
        this.pagination = response.pagination
      })
  }
}
</script>

<style>
</style>